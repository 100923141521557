import {IntakeStatus} from 'definitions/Intake';
import {
  FormSubmissionStatus,
  OnboardingVideoStatus,
  VerificationStatusEnum,
} from 'definitions/Onboarding/provider';
import {magicExerciseSliceInitialState} from 'features/MagicExercise/magicExerciseSlice';
import {magicContentSliceInitialState} from 'features/Provider/Marketing/MagicContent/magicContentSlice';
import {
  AirtableProviderDataTypes,
  Availability,
  AvailabilityData,
  AvailabilityDay,
  AvailableProvider,
  BillingHistoryTypes,
  ColleaguesType,
  CometFriend,
  DigitalPracticeProvider,
  DirectBookingFilterType,
  DirectBookingProviderType,
  InsuranceAndColleagues,
  NormalizedMedications,
  NotesType,
  OptionType,
  PverifyInfoType,
  SliceStatus,
  SocialOnboardingStatusEnum,
  SpecificDayAvailabilityResponse,
  TimeRange,
  WellbitTypeTotal,
} from 'interfaces';
import {Wellbit, WellbitHistory} from 'interfaces/Pages/Provider/Wellbit.types';

import {RootState} from './rootReducer';

export const rootLogoutState: RootState = {
  user: {
    current: null,
    status: SliceStatus.idle,
    logoutStatus: SliceStatus.idle,
    checkSessionStatus: SliceStatus.idle,
    paystackSubaccountStatus: SliceStatus.idle,
    oneTimePasswordVerificationStatus: SliceStatus.idle,
    error: '',
    bootstrap: false,
    isCacheAvailable: false,
    pwaInstallEvt: null,
    notification: {message: '', messageType: 'none'},
    socialOnboardStage: 1,
    chatSettingsStatus: SliceStatus.idle,
    releaseNotes: {
      status: SliceStatus.idle,
      data: [],
    },
    employers: {
      data: [] as OptionType[],
      offset: '',
      hasMore: false,
      status: SliceStatus.idle,
    },
    searchedEmployers: {
      data: [] as OptionType[],
      offset: '',
      hasMore: false,
      status: SliceStatus.idle,
    },
  },
  ui: {
    digitalPracticeProvider: {
      data: {} as DigitalPracticeProvider,
      status: SliceStatus.idle,
    },
    status: SliceStatus.idle,
  },
  payment: {
    status: SliceStatus.idle,
    error: '',
    paypalCards: [],
    paystackCards: [],
    receipts: [],
    paystackAuthorization: {
      status: SliceStatus.idle,
    },
    manageSubStatus: {
      subscriptionState: 'none',
    },
  },
  member: {
    status: SliceStatus.idle,
    languageStatus: SliceStatus.idle,
    error: '',
    accountTab: null,
    uploading: false,
    insuranceNames: [],
    socialOnboardingStatus: SocialOnboardingStatusEnum.ongoing,
    pverifyData: {
      data: {} as PverifyInfoType,
    },
  },
  progress: {
    data: {
      entries: [],
      nextCursor: null,
    },
    status: SliceStatus.idle,
    createStatus: SliceStatus.idle,
    updateProgressAssessmentStatus: SliceStatus.idle,
    moodTrackerStats: {},
    progressAssessmentStats: {},
    error: null,
  },
  appointment: {
    availabilitySlots: {
      slots: [],
      loading: SliceStatus.idle,
    },
    appointments: [],
    dueAppointments: [],
    loading: SliceStatus.idle,
    status: SliceStatus.idle,
    error: '',

    availability: {} as Availability,
    providersLoadingStatus: SliceStatus.idle,
    slotsLoadingStatus: SliceStatus.idle,
    provider: {
      data: {} as AvailableProvider,
      status: SliceStatus.idle,
    },
  },
  notification: {
    entities: {},
    ids: [],
    error: '',
    status: SliceStatus.idle,
    unreadCount: 0,
    total: 0,
    nextPage: null,
    hasMore: false,
  },
  magicContent: magicContentSliceInitialState,
  magicExercise: magicExerciseSliceInitialState,
  // TODO: refactor to share provider notes and members
  provider: {
    internationalOnboardingStatus: null,
    verifiedByWellniteTeam: false,
    paypalMerchantId: '',
    status: SliceStatus.idle,
    languageStatus: SliceStatus.idle,
    providerPicStatus: SliceStatus.idle,
    providerResumeUploadStatus: SliceStatus.idle,
    searchStatus: SliceStatus.idle,
    medicationStatus: SliceStatus.idle,
    noteUpdateStatus: SliceStatus.idle,
    onboardingStatus: SliceStatus.idle,
    onboardingStatusIsInitialized: false,
    expertSummaryStatus: SliceStatus.idle,

    members: {
      members: {},
      lastFetchedMembers: {},
      hasMore: false,
    },
    snapshot: {members: {}, hasMore: false},
    uploading: false,
    notes: {
      data: {} as {[key: string]: NotesType},
      hasMore: false,
      totalNotes: 0,
      nextPage: 0,
      previousPage: 0,
      isNotesLoading: SliceStatus.idle,
    },
    onboarding: {
      stage: 1,
      verificationFormSubmissionDate: 1694050846844,
      verificationStatus: VerificationStatusEnum.sendProfileForm,
      onboardingVideo: OnboardingVideoStatus.notStarted,
      firstTime: true,
      currentStep: 1,
      formStatus: {
        verification: FormSubmissionStatus.notSubmitted,
        profile: FormSubmissionStatus.notSubmitted,
        caqh: FormSubmissionStatus.notSubmitted,
        onboarding: FormSubmissionStatus.notSubmitted,
      },
    },
    billing: {
      appointmentData: [],
      providerEmail: '',
      providerModelRemark: [],
      status: SliceStatus.idle,
    },
    billingHistory: {
      normal: {
        data: [] as BillingHistoryTypes[],
        hasMore: false,
        totalCount: 0,
        nextPage: 0,
        previousPage: 0,
        isLoading: SliceStatus.idle,
      },
      group: {
        data: [] as BillingHistoryTypes[],
        hasMore: false,
        totalCount: 0,
        nextPage: 0,
        previousPage: 0,
        isLoading: SliceStatus.idle,
      },
    },
    error: '',
    medications: {} as NormalizedMedications,
    therapyQuestionnaire: [],
    therapyQuestionnaireStatus: SliceStatus.idle,
    hourlyRate: 0,
    feePercentage: 0,
    setFeePercentageStatus: SliceStatus.idle,
    widgetsPatientsDetails: null,
    widgetsPatientsDetailsStatus: SliceStatus.idle,
    widgetsProviderDetails: null,
    widgetsProviderDetailsStatus: SliceStatus.idle,
    cms1500: {
      status: SliceStatus.idle,
    },
  },
  digitalPractice: {
    stripeConnect: {
      status: SliceStatus.idle,
    },
    southAfricanBankList: {
      data: [],
      status: SliceStatus.idle,
    },
    wellbits: {
      currentAvailable: 0,
      wellbitTypesTotalCount: {} as WellbitTypeTotal,
      totalWellbits: 0,
      status: SliceStatus.idle,
    },

    collaboration: {
      cashingHistory: {
        data: [] as WellbitHistory[],
        hasMore: false,
        pageNo: 1,
        balanceHistory: 0,
      },
      status: SliceStatus.idle,
    },

    referrals: {
      data: [] as ColleaguesType[],
      offset: '',
      hasMore: false,
      status: SliceStatus.idle,
    },

    supervisees: {
      data: [] as ColleaguesType[],
      offset: '',
      hasMore: false,
      status: SliceStatus.idle,
    },

    supervisors: {
      data: [] as ColleaguesType[],
      offset: '',
      hasMore: false,
      status: SliceStatus.idle,
    },
    snapshotColleagues: {
      referrals: {
        data: [] as ColleaguesType[],
        offset: '',
        hasMore: false,
        status: SliceStatus.idle,
      },

      supervisees: {
        data: [] as ColleaguesType[],
        offset: '',
        hasMore: false,
        status: SliceStatus.idle,
      },

      supervisors: {
        data: [] as ColleaguesType[],
        offset: '',
        hasMore: false,
        status: SliceStatus.idle,
      },
    },

    providerWellbits: {
      wellbits: [] as Wellbit[],
      hasMore: false,
      pageNo: 1,
      total: 0,
    },
    wellbit: {
      status: SliceStatus.idle,
    },

    status: SliceStatus.idle,
    searchStatus: SliceStatus.idle,
    error: '',
  },
  router: {
    action: 'POP',
    location: {
      hash: '',
      key: '',
      pathname: '/',
      search: '',
      state: undefined,
      query: {},
    },
  },
  availability: {
    availability: {
      recurring: {
        [AvailabilityDay.Sunday]: [] as TimeRange[],
        [AvailabilityDay.Monday]: [] as TimeRange[],
        [AvailabilityDay.Tuesday]: [] as TimeRange[],
        [AvailabilityDay.Wednesday]: [] as TimeRange[],
        [AvailabilityDay.Thursday]: [] as TimeRange[],
        [AvailabilityDay.Friday]: [] as TimeRange[],
        [AvailabilityDay.Saturday]: [] as TimeRange[],
      },
    } as AvailabilityData,
    status: SliceStatus.idle,
    saveStatus: SliceStatus.idle,
    error: '',
    isAvailabilityModified: false,
    specificDayAvailability: {} as SpecificDayAvailabilityResponse,
  },
  selfAwareness: {
    feedback: {
      data: {
        result: [],
        hasMore: false,
        nextPage: 0,
        prevPage: 0,
        total: 0,
      },
      status: SliceStatus.idle,
    },
    status: SliceStatus.idle,
    error: '',
  },
  messaging: {
    current: null,
    userData: null,
    channel: null,
    loadingMessages: false,
    percentUploaded: 0,
    status: SliceStatus.idle,
    error: '',
    timestamp: {},
    serverTimeOffset: 0,
    typing: {
      isTyping: false,
      id: '',
      fullName: '',
    },
    cometChatUser: null,
    participant: null,
    friendsList: {
      data: [] as CometFriend[],
      status: SliceStatus.idle,
      hasMore: false,
    },
    searchedFriendsList: {
      data: [] as CometFriend[],
      status: SliceStatus.idle,
      hasMore: false,
      keyword: '',
    },
    messages: {
      hasMore: true,
      lastMessageId: '',
      data: [],
    },
    mediaMessages: {
      hasMore: true,
      lastMessageId: '',
      data: [],
    },
    mediaMessagesLoading: SliceStatus.idle,
    unreadMessageCount: 0,
    loading: SliceStatus.idle,
  },
  video: {
    participantJoined: false,
    status: SliceStatus.idle,
    error: '',
    admissionStatus: '',
    rateVideoCall: {appointmentID: ''},
  },
  pushNotification: {
    status: SliceStatus.idle,
    error: '',
    isMobile: true,
    browserName: '',
    isSubscribedToPushNotification: false,
    swInstallTimestamp: Date.now(),
  },
  airtable: {
    providerFieldsOptions: {
      data: {} as {[key: string]: string[]},
      status: SliceStatus.idle,
    },
    providerData: {
      data: {} as AirtableProviderDataTypes,
      isInitialized: false,
      status: SliceStatus.idle,
    },
    insuranceAndColleagues: {
      data: {} as InsuranceAndColleagues,
      status: SliceStatus.idle,
    },
    status: SliceStatus.idle,
    error: '',
  },
  directBooking: {
    filterOptions: {
      data: {} as DirectBookingFilterType,
      status: SliceStatus.idle,
    },
    providers: {
      data: [] as DirectBookingProviderType[],
      offset: '',
      hasMore: false,
      scrollPosition: 0,
      status: SliceStatus.idle,
    },
    provider: {} as DirectBookingProviderType,
    status: SliceStatus.idle,
    error: '',
  },
  currency: {
    data: {},
    status: SliceStatus.idle,
    error: '',
  },
  intake: {
    status: SliceStatus.idle,
    currentStep: 1,
    therapyIntakeStatus: IntakeStatus.notStarted,
    questions: [],
  },
  rating: {
    status: SliceStatus.idle,
    error: '',
  },
};
