import i18next from 'i18next';
import * as Yup from 'yup';

// Validation for generateExercise
export const generateExerciseValidationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required(
      i18next.t('magicExercise.validations.titleRequired', 'Title is required'),
    ),
  description: Yup.string()
    .trim()
    .required(
      i18next.t(
        'magicExercise.validations.descRequired',
        'Description is required',
      ),
    )
    .max(
      120,
      i18next.t(
        'magicExercise.validations.maxChar120',
        'Maximum of 120 characters allowed',
      ),
    ),
  prompts: Yup.array()
    .of(
      Yup.object()
        .shape({
          label: Yup.string(),
          value: Yup.string(),
        })
        .required(
          i18next.t(
            'magicExercise.validations.promptRequired',
            'Prompt is required',
          ),
        ),
    )
    .required(
      i18next.t(
        'magicExercise.validations.promptsFieldRequired',
        'Prompts field is required',
      ),
    ),
});

// Validation for exerciseQuestions
export const exerciseQuestionsValidationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required(
      i18next.t('magicExercise.validations.titleRequired', 'Title is required'),
    ),
  description: Yup.string()
    .trim()
    .required(
      i18next.t(
        'magicExercise.validations.descRequired',
        'Description is required',
      ),
    )
    .max(
      120,
      i18next.t(
        'magicExercise.validations.maxChar120',
        'Maximum of 120 characters allowed',
      ),
    ),
  questions: Yup.array()
    .of(
      Yup.object().shape({
        question: Yup.string()
          .trim()
          .required(
            i18next.t(
              'magicExercise.validations.quesRequired',
              'Question is required',
            ),
          ),
      }),
    )
    .required(
      i18next.t(
        'magicExercise.validations.quesFieldRequired',
        'Questions field is required',
      ),
    ),
});

// Validation for filling up answers
export const exerciseAnswersValidationSchema = Yup.object().shape({
  questions: Yup.array().of(
    Yup.object().shape({
      answer: Yup.string(),
    }),
  ),
});
