import {RootState} from 'app/rootReducer';

import {createSelector} from '@reduxjs/toolkit';

import {MagicExerciseState} from './magicExerciseSlice';

export function getMagicExercise(state: RootState): MagicExerciseState {
  return state.magicExercise;
}

export const selectMagicGeneratedExercise = createSelector(
  getMagicExercise,
  state => state.generatedExercise,
);

export const selectMagicExercise = createSelector(
  getMagicExercise,
  state => state.exercise,
);

export const selectMagicRegeneratedQuestions = createSelector(
  getMagicExercise,
  state => state.questions,
);

export const selectMagicRegenerateExerciseStatus = createSelector(
  getMagicExercise,
  state => state.regenerateExerciseStatus,
);

export const selectExerciseFormStep = createSelector(
  getMagicExercise,
  state => state.formStep,
);

export const selectMagicExercises = createSelector(
  getMagicExercise,
  state => state.exercises,
);

export const selectMagicExercisesStatus = createSelector(
  getMagicExercise,
  state => state.exercisesStatus,
);
