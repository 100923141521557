import React, {FC} from 'react';
import RegenerateIcon from 'assets/images/marketing/regenerate-icon.png';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';

type IProps = {
  imageClasses?: string;
  buttonClasses?: string;
  isRegenerating?: boolean;
  onRegenerateClick: VoidFunction;
};

const RegenerateButton: FC<IProps> = ({
  buttonClasses,
  imageClasses = 'w-3 h-3',
  isRegenerating,
  onRegenerateClick,
}) => {
  const {t} = useTranslation();
  return (
    <button
      type="button"
      className={`font-inter text-xs leading-3 font-medium text-darkGray underline bg-transparent bottom-0 outline-none flex items-center gap-1.5 ${buttonClasses}`}
      onClick={onRegenerateClick}
      disabled={isRegenerating}
    >
      <img
        className={classNames(
          'inline-block',
          {'animate-ping': isRegenerating},
          imageClasses,
        )}
        src={RegenerateIcon}
        alt={t('magicExercise.regenerate', 'Regenerate')}
      />
      {isRegenerating
        ? t('magicExercise.regenerating', 'Regenerating...')
        : t('magicExercise.regenerate', 'Regenerate')}
    </button>
  );
};

export default RegenerateButton;
