import {UserRoles} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

import {
  ExercisesPaginationType,
  ExerciseType,
  GeneratedExerciseData,
  GeneratedExerciseResponse,
  GenerateExerciseProps,
  SaveExerciseProps,
  UpdateExerciseAnswersType,
} from './utils/constants';

// Provider Actions
// Generate exercise
export const generateExercise =
  createAction<GenerateExerciseProps>('GenerateExercise');
export const generateExerciseFailure = createAction<string>(
  'GenerateExerciseFailure',
);
export const generateExerciseSuccess = createAction<GeneratedExerciseData>(
  'GenerateExerciseSuccess',
);

// Regenerate specific question
export const regenerateExerciseQuestion = createAction<GenerateExerciseProps>(
  'RegenerateExerciseQuestion',
);
export const regenerateExerciseQuestionFailure = createAction<string>(
  'RegenerateExerciseQuestionFailure',
);
export const regenerateExerciseQuestionSuccess =
  createAction<GeneratedExerciseResponse>('RegenerateExerciseQuestionSuccess');

// save exercise draft as generatedExercise
export const saveExerciseDraft =
  createAction<GeneratedExerciseData>('SaveExerciseDraft');

// Create exercise
export const publishExercise =
  createAction<SaveExerciseProps>('PublishExercise');
export const publishExerciseFailure = createAction<string>(
  'PublishExerciseFailure',
);
export const publishExerciseSuccess = createAction<ExerciseType>(
  'PublishExerciseSuccess',
);

// Reset magic exercise state
export const resetMagicExercise = createAction('ResetMagicExercise');

// Member Actions
// Get all the exercises with patient email
export const getExercises = createAction<{
  patientId?: string;
  page: number;
  pageSize: number;
  userRole: UserRoles;
}>('GetExercises');
export const getExercisesFailure = createAction<string>('GetExercisesFailure');
export const getExercisesSuccess = createAction<{
  exercises: ExerciseType[];
  pagination: ExercisesPaginationType;
}>('GetExercisesSuccess');
export const resetExercises = createAction('ResetExercises');

// Update exercise answers
export const updateExerciseAnswers = createAction<UpdateExerciseAnswersType>(
  'UpdateExerciseAnswers',
);
export const updateExerciseAnswersFailure = createAction<string>(
  'UpdateExerciseAnswersFailure',
);
export const updateExerciseAnswersSuccess = createAction<ExerciseType>(
  'UpdateExerciseAnswersSuccess',
);

// send email notification for initial exercise
export const sendInitialExerciseRequest = createAction(
  'SendInitialExerciseRequest',
);
export const sendInitialExerciseRequestFailure = createAction<string>(
  'SendInitialExerciseRequestFailure',
);
export const sendInitialExerciseRequestSuccess = createAction(
  'SendInitialExerciseRequestSuccess',
);
