import React, {FC, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import {BackgroundContainer} from 'components';
import {useDispatch, useSelector} from 'react-redux';

import {Dialog} from '@headlessui/react';

import {resetMagicExercise} from '../magicExerciseActions';
import {selectExerciseFormStep} from '../magicExerciseSelectors';

import EditExerciseQuestions from './screens/EditExerciseQuestions';
import ExerciseQuestionsPreview from './screens/ExerciseQuestionsPreview';
import GenerateExerciseTemplate from './screens/GenerateExerciseTemplate';

export enum FormSteps {
  generateExerciseTemplate = 'generateExerciseTemplate',
  editExerciseQuestions = 'editExerciseQuestions',
  exerciseQuestionsPreview = 'exerciseQuestionsPreview',
}

type StepsProps = {
  Component: FC<any>;
  nextStep?: FormSteps;
};

const exerciseComponentSteps: Record<FormSteps, StepsProps> = {
  generateExerciseTemplate: {
    Component: GenerateExerciseTemplate,
    nextStep: FormSteps.editExerciseQuestions,
  },
  editExerciseQuestions: {
    Component: EditExerciseQuestions,
    nextStep: FormSteps.exerciseQuestionsPreview,
  },
  exerciseQuestionsPreview: {Component: ExerciseQuestionsPreview},
};

type IProps = {
  onOpen: boolean;
  onClose: VoidFunction;
};

const CreateExercise: FC<IProps> = ({onOpen, onClose}) => {
  const dispatch = useDispatch();
  const formStep = useSelector(selectExerciseFormStep);
  const [step, setStep] = useState<FormSteps>(
    FormSteps.generateExerciseTemplate,
  );
  const component = useMemo(() => exerciseComponentSteps[step], [step]);
  const stepsKeys = Object.keys(exerciseComponentSteps) as FormSteps[];

  useEffect(() => {
    if (formStep) {
      handleClose();
      dispatch(resetMagicExercise());
    }
  }, [formStep]);

  const handleSubmit = () => {
    if (step === stepsKeys[stepsKeys.length - 1]) {
      onClose();
    } else {
      setStep(component?.nextStep ?? FormSteps.generateExerciseTemplate);
    }
  };

  const handleEdit = () => {
    const key = stepsKeys.indexOf(step);
    stepsKeys[key - 1] && setStep(stepsKeys[key - 1]);
  };

  const handleClose = () => {
    dispatch(resetMagicExercise());
    onClose();
    setStep(FormSteps.generateExerciseTemplate);
  };

  return (
    <Dialog open={onOpen} onClose={onClose} className="">
      <div className="fixed inset-0 flex md:items-center justify-center z-50">
        <Dialog.Panel className="mx-auto w-full h-full bg-gray-100 relative">
          <div id="action-buttons-portal" />
          <BackgroundContainer
            containerClasses="p-4 overflow-y-auto !w-full !h-full !pt-16"
            contentClasses={classNames({
              'md:pt-28': step === FormSteps.generateExerciseTemplate,
              'Md:pt-10': step !== FormSteps.generateExerciseTemplate,
            })}
            onClick={handleClose}
          >
            <div className="mx-auto pb-10 max-w-4xl">
              {component ? (
                <component.Component
                  onSubmit={handleSubmit}
                  onBackEdit={handleEdit}
                />
              ) : null}
            </div>
          </BackgroundContainer>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default CreateExercise;
