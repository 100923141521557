import React, {FC, useCallback} from 'react';
import classNames from 'classnames';
import {Button, LeftNavIcon, RightNavIcon} from 'components';
import {publishExercise} from 'features/MagicExercise/magicExerciseActions';
import {selectMagicGeneratedExercise} from 'features/MagicExercise/magicExerciseSelectors';
import {ExerciseStepsProps} from 'features/MagicExercise/utils/constants';
import {useRequesting} from 'hooks';
import {SliceStatus} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

const ExerciseQuestionsPreview: FC<ExerciseStepsProps> = ({
  onSubmit,
  onBackEdit,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {title, description, questions, prompts, patientId} = useSelector(
    selectMagicGeneratedExercise,
  );
  const publishExerciseStatus = useRequesting('magicExercise');

  const handleEditClick = useCallback(() => {
    onBackEdit?.();
  }, [onBackEdit]);

  const handleSendClick = useCallback(() => {
    dispatch(
      publishExercise({
        title,
        description,
        questions,
        topics: prompts,
        patientId,
        onSuccess: onSubmit,
      }),
    );
  }, [dispatch, title, description, questions, prompts, patientId]);

  const isPublishingExercise = publishExerciseStatus === SliceStatus.pending;
  return (
    <div className="flex flex-col justify-center relative">
      <div className="w-full md:w-8/12 md:max-w-xl h-auto flex flex-col gap-12 px-6 py-8 md:p-12 pb-24 mx-auto mb-10 bg-white border border-gray-200 rounded-2xl shadow-2xl">
        <div className="flex flex-col gap-2">
          <h3 className="font-montserrat text-3xl font-bold m-0">{title}</h3>
          <p className="font-inter text-sm font-medium">{description}</p>
        </div>
        <div className="flex flex-col gap-4">
          {questions.map((question, idx) => (
            <div key={`${question}`} className="flex flex-col">
              <div className="flex flex-row gap-1 mb-3">
                <span className="relative left-0 top-0 font-inter text-sm leading-4 font-medium">
                  {idx + 1}.
                </span>
                <p className="font-inter text-sm leading-4 font-medium">
                  {question}
                </p>
              </div>
              <p className="w-full h-12 border rounded-xl bg-white p-0 m-0" />
            </div>
          ))}
        </div>
      </div>

      <Button
        btnType="custom"
        fontSize="sm"
        fontWeight="medium"
        textColor="black"
        bgColor="white"
        borderColor="gray-400"
        borderRadius="full"
        className={classNames(
          'z-999 fixed left-10 bottom-10 w-auto h-12 flex items-center justify-center px-6 font-inter !leading-3 hover:bg-black hover:text-white hover:border-black',
          {
            'bg-black': isPublishingExercise,
          },
        )}
        onClick={handleEditClick}
        disabled={isPublishingExercise}
      >
        <LeftNavIcon
          width={18}
          height={18}
          strokeColor="black"
          strokeWidth={2}
        />
        &nbsp;{t('magicExercise.edit', 'Edit')}
      </Button>
      <Button
        btnType="primary"
        fontSize="sm"
        fontWeight="medium"
        borderRadius="full"
        hover={false}
        className={classNames(
          'z-999 fixed right-10 bottom-10 w-auto h-12 flex items-center justify-center px-6 font-inter !leading-3',
          {
            'bg-opacity-70': isPublishingExercise,
          },
        )}
        onClick={handleSendClick}
        disabled={isPublishingExercise}
      >
        {t('magicExercise.send', 'Send')}&nbsp;
        <RightNavIcon
          width={18}
          height={18}
          strokeColor="black"
          classes={classNames({
            'animate-ping': isPublishingExercise,
          })}
          strokeWidth={2}
        />
      </Button>
    </div>
  );
};

export default ExerciseQuestionsPreview;
