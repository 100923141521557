import {appointmentEpics} from 'features/Appointment';
import {currencyEpics} from 'features/Currency/currencyEpics';
import {directBookingEpics} from 'features/DirectBooking/directBookingEpics';
import {intakeEpics} from 'features/Intake/intakeEpics';
import {magicExerciseEpics} from 'features/MagicExercise/magicExerciseEpics';
import {memberEpics} from 'features/Member';
import {progressEpics} from 'features/Member/Progress/progressEpics';
import {selfAwarenessEpics} from 'features/Member/SelfAwareness/selfAwarenessEpics';
import {messagingEpics} from 'features/Messaging';
import {notificationsEpics} from 'features/Notification';
import {paymentEpics} from 'features/Payment';
import {providerEpics} from 'features/Provider';
import {airtableProviderEpics} from 'features/Provider/Airtable';
import {digitalPracticeEpics} from 'features/Provider/DigitalPractice/digitalPracticeEpics';
import {pushNotificationEpics} from 'features/PushNotification';
import {userEpics} from 'features/User';
import {videoEpics} from 'features/Video';
import {combineEpics} from 'redux-observable';

import {appEpics} from './appEpics';

import {ratingEpics} from 'Rating';

export default combineEpics(
  ...userEpics,
  ...paymentEpics,
  ...memberEpics,
  ...progressEpics,
  ...selfAwarenessEpics,
  ...appointmentEpics,
  ...appEpics,
  ...notificationsEpics,
  ...videoEpics,
  ...ratingEpics,
  ...pushNotificationEpics,
  ...providerEpics,
  ...messagingEpics,
  ...airtableProviderEpics,
  ...digitalPracticeEpics,
  ...directBookingEpics,
  ...currencyEpics,
  ...intakeEpics,
  ...magicExerciseEpics,
);
